import React, { useContext, useRef, useState } from 'react'

import Axios from 'axios'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'

import AppContext from '../../utils/context'

const IMAGE_LIMIT_MB = 3
const PDF_LIMIT_MB = 20
const ZIP_LIMIT_MB = 50

function App ({
    uploadUrl,
    filePicked,
    fileUploaded,
    params = {},
    allowZip = true,
    setUploading: setParentUploading,
    allowImage = false,
    setParentFileValue,
    idName,
    versionComponent = 1
}) {
    const fileInputRef = useRef(null)
    const { pushNotification } = useContext(AppContext)
    const [file, setFile] = useState(null)
    const [filePickClicked, setFilePickClicked] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [previewFile, setPreviewFile] = useState('')

    const resetInput = () => {
        // Clears input.
        setFile(null)
        fileInputRef.current.value = ''
        setPreviewFile('')
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0]
        if (!selectedFile) return

        const allowedFileTypeConfigs = [
            {
                type: 'application/pdf',
                shortName: 'PDF',
                maxFileSize: PDF_LIMIT_MB * 1024 * 1024,
                maxFileErrorMessage: 'Please select a PDF / ZIP file only'
            }
        ]

        if (allowImage) {
            const imageTypes = [
                {
                    type: 'image/jpeg',
                    shortName: 'JPEG',
                    maxFileSize: IMAGE_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: `Please select a JPEG file under ${IMAGE_LIMIT_MB} MB`
                },
                {
                    type: 'image/png',
                    shortName: 'JPEG',
                    maxFileSize: IMAGE_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: `Please select a PNG file under ${IMAGE_LIMIT_MB} MB`
                }
            ]

            imageTypes.forEach(x => allowedFileTypeConfigs.push(x))
        }

        if (allowZip) {
            const zipTypes = [
                {
                    type: 'application/zip',
                    shortName: 'ZIP',
                    maxFileSize: ZIP_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: 'Too many files in ZIP file'
                },
                {
                    type: 'application/x-zip-compressed',
                    shortName: 'ZIP',
                    maxFileSize: ZIP_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: 'Too many files in ZIP file'
                },
                {
                    type: 'application/zip-compressed',
                    shortName: 'ZIP',
                    maxFileSize: ZIP_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: 'Too many files in ZIP file'
                },
                {
                    type: 'application/x-zip',
                    shortName: 'ZIP',
                    maxFileSize: ZIP_LIMIT_MB * 1024 * 1024,
                    maxFileErrorMessage: 'Too many files in ZIP file'
                }
            ]

            zipTypes.forEach(x => allowedFileTypeConfigs.push(x))
        }

        const allowedShortNames = [...new Set(allowedFileTypeConfigs.map(x => x.shortName))]
        const config = allowedFileTypeConfigs.find(x => x.type === selectedFile.type)
        if (!config) {
            pushNotification('error', `Please select a ${allowedShortNames.join(' / ')} file only`)
            resetInput()
            return
        }

        if (selectedFile.size > config.maxFileSize) {
            pushNotification('error', config.maxFileErrorMessage)
            resetInput()
            return
        }

        setFile(selectedFile)

        if (filePicked && typeof filePicked === 'function') {
            filePicked(selectedFile)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('file', file)
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                formData.append(key, value)
            })
        }

        try {
            setUploading(true)
            if (setParentUploading && typeof setParentUploading === 'function') {
                setParentUploading(true)
            }

            const { data: resultData } = await Axios.post(uploadUrl, formData)
            pushNotification('success', resultData.message)

            if (fileUploaded && typeof fileUploaded === 'function') {
                fileUploaded(file, filePickClicked)
            }

            resetInput()
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setUploading(false)
            if (setParentUploading && typeof setParentUploading === 'function') {
                setParentUploading(false)
            }
        };
    }

    const handleSubmitVersion = async (version) => {
        const formData = new FormData()
        formData.append('file', file)
        try {
            setUploading(true)
            if (setParentUploading && typeof setParentUploading === 'function') {
                setParentUploading(true)
            }

            const { data: resultData } = await Axios.post(uploadUrl, formData)
            pushNotification('success', resultData.message)

            if (setParentFileValue && typeof setParentFileValue === 'function') {
                setParentFileValue(idName, resultData.data.s3_uri)
            }

            setPreviewFile(resultData.data.file_name)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setUploading(false)
            if (setParentUploading && typeof setParentUploading === 'function') {
                setParentUploading(false)
            }
        };
    }

    const viewFileOnLocal = () => {
        const reader = new FileReader()
        reader.onload = function (e) {
            const blob = new Blob([e.target.result], { type: file.type })
            const url = URL.createObjectURL(blob)
            window.open(url, '_blank')
        }
        reader.readAsArrayBuffer(file)
    }

    const handleBtnPickFile = () => {
        setFilePickClicked(true)
    }

    return (
        <Row>
            {
                versionComponent === 1 && (
                    <>
                        <Col sm={8}>
                            <Form onSubmit={handleSubmit}>
                                <InputGroup size="sm">
                                    <Form.Control type="file" ref={fileInputRef} onChange={handleFileChange} onClick={handleBtnPickFile} disabled={uploading} />
                                    <Button variant="primary" type="submit" disabled={!file || uploading}>
                                        Upload
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                        {uploading && (<Spinner animation="border" variant="primary" className="me-2" />)}
                    </>
                )
            }
            {
                versionComponent === 1.1 && (
                    <>
                        <Col sm={11}>
                            <InputGroup size="sm">
                                <Form.Control type="file" ref={fileInputRef} onChange={handleFileChange}
                                    onClick={handleBtnPickFile} disabled={uploading || previewFile} />
                                {
                                    !previewFile && (
                                        <Button onClick={() => handleSubmitVersion(versionComponent)}
                                            variant="primary" disabled={!file || uploading}>
                                        Upload
                                        </Button>
                                    )
                                }
                                {
                                    file && previewFile && (
                                        <Button onClick={() => viewFileOnLocal()}
                                            variant="success" disabled={!file || uploading}>
                                        Lihat File
                                        </Button>
                                    )
                                }
                                {
                                    file && (
                                        <Button onClick={() => resetInput()} className="ms-2"
                                            variant="danger" disabled={!file || uploading}>
                                        Hapus File
                                        </Button>
                                    )
                                }
                            </InputGroup>
                        </Col>
                        <Col sm={1}>
                            {uploading && (<Spinner animation="border" variant="primary" className="me-2" />)}
                        </Col>
                    </>
                )
            }
        </Row>
    )
}

export default App
